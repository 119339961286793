<template>
  <CRow>
    <CCol col="12">
      <HelpModal 
        titel="Help - Customers"        
        :is-open="helpIsOpen"
        @update="openHelp"
      >
        <div slot="body">
          <p>
            On this page you can see all Customers which have been created in the HAWK Tool. 
            You can create, edit and delete Customers on this page. 
          </p>
          <p>
            Creating a Customer is the first action to take when we create Reports for a WAfR.
          </p>
          <hr>
          <p>
            A Customer always has a name and an email-address. Optionally, you can add a description.
            Everything, <b>expect</b> the email-address can be changed afterwards.
          </p>
          <hr>
          <p>
            <b>Inside a Customer</b> you find the Credentials needed to access the Reports. To show them press the <b>Retrieve Credentials</b> Button.
          </p>
          <hr>
          <p>
            <b>Be careful when you delete a Customer</b>, especially if it's linked to Reports.
          </p>
        </div>      
      </HelpModal>  
    </CCol>

    <CCol col="12" xl="8" style="max-width: 80rem;">
      <CCard>
        <CCardHeader>
          <CButton class="float-left"  variant="ghost" color="dark" disabled>Customers</CButton>
          <CButton class="ml-5 float-right"   @click="openHelp(true)" color="dark"><span class="material-icons md-18">question_mark</span></CButton>
          <CButton class="ml-5 float-right col-sm-4 col-xl-2" variant="outline" @click="createCustomer" color="success">Create Customer</CButton>
        </CCardHeader>      
        <CCardBody>
          <CDataTable
            hover
            striped
            sorter       
            cleaner     
            column-filter
            items-per-page-select
            :sorter-value="{ column: 'customer_name', asc: 'true' }"
            :items="customers"
            :fields="fields"
            :items-per-page="20"
            :active-page="activePage"
            :pagination="{ doubleArrows: true, align: 'center'}"
            :loading=loadingCustomersTable
          > 

            <td slot="customer_desc" slot-scope="{item}"> 
              <div class="tooltip-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
                <span class="d-inline-block text-truncate" style="max-width: 250px;">
                  {{ item.customer_desc }}                  
                </span>  
                <div class="custom-tooltip" v-show="showTooltip">
                  {{ item.customer_desc }}                  
                </div>
              </div>        
            </td> 
            
            <td slot="customer_email" slot-scope="{item}">
              {{ item.customer_email || "N/A" }}
            </td>

            <td slot="customer_author" slot-scope="{item}">
              {{ item.customer_author.customer_author_name || "undefined" }}
            </td>

            <td slot="customer_edit" slot-scope="{item}">
              <CButton v-on:click="openCustomer(item.customer_id)" :id="item.customer_id" color="dark" style="display: flex;"><span class="material-icons md-18">edit</span></CButton>
            </td>

            <td slot="customer_delete" slot-scope="{item}">
              <CButton v-on:click="deleteCustomer(item.customer_id)" :id="item.customer_id" color="danger" style="display: flex;"> <span class="material-icons md-18">delete</span></CButton>
            </td>      

          </CDataTable>
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import CustomerService from '@/services/customer.services';
import HelpModal from '@/views/help/HelpModal.vue';

export default {
  components: {
    HelpModal,
  },  
  data() {
    return {
      customers: [],
      fields: [
        { key: 'customer_name', label: 'Name', filter: true, sorter: true , _style:'width:10%' },
        { key: 'customer_desc', label: 'Description', filter: false, sorter: false , _style:'width:20%' },
        { key: 'customer_email', label: 'Email', filter: true, sorter: false , _style:'width:10%' },
        { key: 'customer_author', label: 'Author', filter: true, sorter: true , _style:'width:5%' },
        { key: 'customer_edit', label: '', filter: false, sorter: false , _style:'width:1%' },
        { key: 'customer_delete', label: '', filter: false, sorter: false , _style:'width:1%' },
      ],
      activePage: 1,
      helpIsOpen: false,
      loadingCustomersTable: true,
      showTooltip: false,
    };
  },
  setup() { },
  methods: {
    deleteCustomer(id) {
     this.$router.push({path: '/customers/'+id+'/delete'})
    },
    createCustomer() {
      this.$router.push({path: '/customers/create'})
    },
    openCustomer(item_id) {
      this.$router.push({path: `customers/${item_id}`})
    },
    pageChange(val) {
      this.$router.push({ query: { page: val }})
    },
    openHelp(value) {
      this.helpIsOpen = value
    }
  },
  created() {},
  mounted() {
    CustomerService.getCustomers().then(
      response => { 
        this.customers = response;
        this.loadingCustomersTable = false;
      },
      error => {   
        console.log(error)   
      }      
    ) 
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
};

</script>
